body{
    background: #000;
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    font-display: swap; 
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap') format('woff2');
  font-display: swap;
}

//Site Button
.site-btn{
    border: solid 1px #fff;
    border-radius: 5px;
    padding: 10px 25px;
    color: #fff;
    font-weight: 600;
    font-size: 0.9rem;
    transition-duration: 200ms;
     transition: all linear .2s;
    -webkit-transition: all linear 0.2s ;
    overflow: hidden;
    position: relative;
    @media (max-width: 992px) {
        font-size: 0.7rem;
    }
    @media (max-width: 480px) {
        padding: 5px 20px;
    }
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent,rgba(255, 255, 255, 0.3),transparent);
        transition: 0.5s;
        -webkit-animation: shade-anim 3s linear infinite;
        animation: shade-anim 3s linear infinite;
    }
    &:hover{
        background: #fff;
        color:#040612;
    }

}
@keyframes shade-anim {
    0%{
        left: -60%;
    }
    50%{
        left: 100%;
    }
     100%{
        left: -60%;
    }
}

.site-btn1 {
  background: #fff3;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  font-size: .9rem;
  font-weight: 600;
  overflow: hidden;
  padding: 10px 25px;
  position: relative;
}

.site-sub-btn{
    border: solid 1px #fff;
    border-radius: 5px;
    padding: 10px 25px;
    color: #fff;
    font-weight: 600;
    font-size: 0.9rem;
    transition: all linear .2s;
    -webkit-transition: all linear 0.2s ;
    overflow: hidden;
    position: relative;
    @media (max-width: 992px) {
        font-size: 0.7rem;
    }
    @media (max-width: 480px) {
        padding: 5px 20px 5px 20px;
    }
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent,rgba(255, 255, 255, 0.559),transparent);
        transition: 0.5s;
    }
    &:hover{
        text-decoration: none;
        color:#fff;
;
        &::after{
            left: 100%;
        }
    }
    &:focus{
        text-decoration: none;
        box-shadow: none;
        color: #fff;
    }

}
@keyframes shade-anim1 {
    0%{
        left: -60%;
    }
    100%{
        left: 100%;
    }
}

.site-sub-btn1{
    border-radius: 5px;
    padding: 10px 25px;
    color: #fff;
    font-weight: 500;
    font-size: 0.9rem;
    position: relative;
    text-align: center;
    @media (max-width: 992px) {
        font-size: 0.7rem;
    }
    @media (max-width: 480px) {
        padding: 5px 20px 5px 20px;
    }
    &:hover{
        background: #fff;
        color: #000;
    }
   

}
.NewsFilterButton{
 .active{
     color: #000;
        background: #fff;
    }
}

.newtext{
    a{
    color: #495efd;
    }
}

//loader

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #000000; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


h1{
        font-size: 2rem;
        font-weight: 600;
        color: #fff;
        text-transform: capitalize;
        line-height: 1.1;
        margin-bottom: 10px;
        @media (max-width: 992px) {
            font-size: 1.6rem;
        }
        @media (max-width: 576px) {
            font-size: 1.2rem;
        }
}

h2{
        font-size: 2rem;
        font-weight: 600;
        color: #fff;
        text-transform: capitalize;
        line-height: 1.1;
        margin-bottom: 10px;
        @media (max-width: 992px) {
            font-size: 1.6rem;
        }
        @media (max-width: 576px) {
            font-size: 1.2rem;
        }
}
h5{
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 10px;
    @media (max-width: 992px) {
        font-size: 1rem;
        line-height: 1.2rem;
    }
    @media (max-width: 576px) {
        font-size: 0.9rem;
    }
}
h3{
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 10px;
    @media (max-width: 992px) {
        font-size: 1rem;
        line-height: 1.2rem;
    }
    @media (max-width: 576px) {
        font-size: 0.9rem;
    }
}
p{
    color: #979797;
    margin-bottom: 20px;
    font-size: 1rem;
    @media (max-width:768px) {
        font-size: 0.8rem;
        
    }
}

//loading text
.load-text{
        color: #FAC25A;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1;
}
.loadDot{
    width: 5px;
    height: 5px;
    background: #FAC25A;
    border-radius: 50px;
    position: relative;
    margin-bottom: 1.5px;
    margin-left: 2px;
    margin-right: 2px;
    
}
.dot1{
    -webkit-animation: bling-anim1 3s linear infinite;
    animation: bling-anim1 3s linear infinite;
}
.dot2{
    -webkit-animation: bling-anim2 3s linear infinite;
    animation: bling-anim2 3s linear infinite;
}
.dot3{
 -webkit-animation: bling-anim3 3s linear infinite;
    animation: bling-anim3 3s linear infinite;
}
@keyframes bling-anim1 {
    0%{
        opacity: 1;
    }
    60%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes bling-anim2 {
    0%{
        opacity: 0;
    }
     40%{
        opacity: 1;
    }
    70%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes bling-anim3 {
    0%{
        opacity: 1;
    }
     70%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

//bouce right
.arrow-r-bounce{
    -webkit-animation: bounce-right 1s linear infinite;
    animation: bounce-right 1s linear infinite;
}
@keyframes bounce-right {
  0%, 100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.bannerx{
    background-position: 30% !important;
}

//Banner section
.banner{
    background-size: cover;
    background-position: 80%;
    background-repeat: no-repeat;
    min-height: 500px; 
    width: 100%;
     @media (max-width: 768px) {
            min-height: 300px; 
        }
    
    .banner-h1{
        max-width: 70%;
        @media (max-width: 768px) {
            max-width: 80%;
        }
        @media (max-width: 450px) {
            max-width: 100%;
        }
    }
    p{
        font-size: 1rem;
        color: #fff;
        @media (max-width: 768px) {
            font-size: 0.8rem;
        }
    }
    .banner-content{
        padding: 10rem 0rem;
        @media (max-width: 768px) {
            padding: 5rem 0rem;
        }
        @media (max-width: 640px) {
            padding: 3.2rem 0rem;
        }
    }
    h2{
        color: #fff;
        font-size: 1.2rem;
         @media (max-width: 768px) {
            font-size: .9rem;
        }
    }
}
//Who we are

//Featured Off-Plan Projects

//Our Services
//Reasons 
//Our Exclusive Team
.team-card{
    h5{
        margin-bottom: 0px;
    }
}
//Our Process
//What Our Clients Say
//Our Partners
//Let's Talk Together
.talkSection{
    color: #fff;
}
.phoneInput{
    .form-control{
        background: transparent !important;
        border: none !important;
    }
    .country-name{
        color: #000000;
    }
}
//footer
.footer-head{
    border-bottom: solid 1px #575769;
}
.social-links{
    
    li{
        color: #ffff;
        font-size: 1.5rem;
         height: 40px;
        width: 40px;
       
        &:hover{
            color: #CE8745;
        }
        a{
            svg{
            height: 40px;
            width: 40px;
            padding: 5px;
            }
        }
    }
}
.footer-body{
    h6{
        color: #ffffff;
        font-size: 1.2rem;
        margin-bottom: 0.8rem;
        font-weight: 600;
    }
    li{
        margin-bottom: 10px;  
    }
    a{
        color: #ffffff;
        font-size: 0.9rem;
        
        &:hover{
            color: #CE8745;
        }
    }
    .footer-connects{
        a{
            display: flex;
            align-items: start;
            
        }
    }
}

.scrollTop{
    transition: all 0.5s ease 0s;
    display: none;
    background: #ffffff;
    color: #000000;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    &:hover{
        background: #CE8745 ;
        font-size: 1.5rem;
        color:#040612;
    }
}

.scrollTop-widget{
    position: fixed;
    bottom: 40px;
    right: 30px;
    cursor: pointer;
    z-index: 9999;
    @media (max-width:768px) {
        left: 30px;
        bottom: 20px;
        right: unset;
    }
}

.whatsapp-widget{
    position: fixed;
    bottom: 110px;
    right: 30px;
    cursor: pointer;
    z-index: 9999;
    @media (max-width:768px) {
        bottom: 20px;
    }
}



//project detail page
.white-cover{
    background: rgba(255, 255, 255, 0.62);
    width: 100%;
    height: 100%;
    text-align: center;
}

// //dashboard Home
.dashboardHome{
    margin-bottom: 1rem;
    // .active{
    //     background: #CE8745 ;
    //     border: none;
    // }
    
}


//table

table{
    border: 1px solid #575769;
    td{
        border: 1px solid #575769;
        padding: 0.5rem;
    }
    th{
        border: 1px solid #575769;
    }
}

//dashboard sidebar
.sideBarDashboard{
    .active{
        background: #18A4A0 ;
        border: none;
    }
}

//map location
// width="100%" height="" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0
.map{ 
    width: 100%; 
    height: 324px; 
    filter: grayscale(100%) invert(92%) contrast(83%);
    border: n;
}

.b24-widget-button-position-bottom-right {
  bottom: 124px !important;
  right: 40px !important;
  @media (max-width:768px) {
    bottom: 20px !important;
    right: 23px !important;
  }
}

#content1 {
  height: 1000px;
}

#stickyDiv {
  display: none;
  opacity: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  transform: translateY(-250px);
  transition: transform 2s ease-in-out,opacity 2s ease-in-out;
}

.sticky-active {
  display: block !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.bounce-left {
  animation: bounceleft 1s infinite;
}

@keyframes bounceleft {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

.bounce-top {
  animation: bouncetop 1s infinite;
}


@keyframes bouncetop {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}


.tabs{
    .tab-btn {
        background: #000;
        border: 1px solid #fff;
        border-radius: 0 15px 0 0;
        color: #fff;
        font-size: .9rem;
        font-weight: 600;
        margin-left: -14px;
        overflow: hidden;
        padding: 10px 25px;
        position: relative;
        transition-duration: .2s;
        &:hover{
         background: #ce8745;
        border: 1px solid #fff;
        color: #040612;
        }
    }
    .active{
        background: #ce8745;
        border: 1px solid #fff;
        color: #040612;
    }
} 


.tabs1{
    .tab-btn{
        background: #000;
        border: 1px solid #fff;
        border-radius: 0 15px 0 0;
        color: #fff;
        font-size: .9rem;
        font-weight: 600;
        margin-left: -14px;
        overflow: hidden;
        padding: 10px 25px;
        position: relative;
        transition-duration: .2s;
        &:hover{
         background: #CFA028;
        border: 1px solid #fff;
        color: #040612;
        }
    }
    .active{
        background: #CFA028;
        border: 1px solid #fff;
        color: #040612;
    }
} 

.pagination-block ul li {
  border: 1px solid #fff;
  padding: 3px 7px;
}

.pagination-block ul {
  align-items: center;
  display: flex;
  gap: 10px;
}


@keyframes zoomFadeOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.logo-animation {
  animation: zoomFadeOut 2s forwards;
  width: 85px; 
  height: auto;
  display: block;
  margin: auto;
  @media (max-width:768px) {
    width: 45px;
  }
}

.NewsBlock {
    ul {
        direction: ltr; 
        list-style-type: disc;
        padding-left: 20px;
    }
}

.NewsFilterButton{
 .active{
     color: #000;
        background: #fff;
    }
}

.newtext{
    a{
    color: #495efd;
    }
}